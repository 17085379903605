import OAuthService from "@/common/oauth.service";
import axios from "axios";
import {API_OAUTH_URL, API_CLIENT_ID, API_URL} from "@/common/config";
import ApiService from "@/common/api.service";

const authClient = axios.create({
    baseURL: API_OAUTH_URL,
    headers: {
        accept: 'application/vnd.api+json'
    }
});

const state = {
    errors: null,
    isAuthenticated: !!OAuthService.getToken(),
};

const actions = {
    login(context, credentials) {
        const formParams = {
            grant_type: 'password',
            client_id: API_CLIENT_ID,
            username: credentials.email,
            password: credentials.password,
            scope: ''
        }

        context.commit("PURGE_AUTH");

        return authClient.post(`${API_OAUTH_URL}/token`, formParams)
            .then(response => {
                context.commit("SET_AUTH", response.data);
            });
    },

    forgotPassword(context, payload) {
        return axios.post(API_URL + '/clients/forgot-password', {
            email: payload.email
        })
    },

    loginViaToken(context, payload) {
        context.commit("PURGE_AUTH");

        context.commit("SET_AUTH", { ...payload });
    },

    logout(context) {
        context.commit("PURGE_AUTH");
    }
}

const mutations = {
    SET_AUTH(state, data) {
        state.isAuthenticated = true;
        state.errors = {};
        OAuthService.saveToken(data.access_token);
    },
    PURGE_AUTH(state) {
        state.isAuthenticated = false;
        state.errors = {};
        window.localStorage.clear()
    },
};

const getters = {
    isAuthenticated(state) {
        return state.isAuthenticated;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};